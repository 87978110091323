<template>
  <div class="w100 dp-f statisticsbox">
    <div class="bg-fff h-180 w100 dp-f jc-sa ai-c pl-10 pr-10">
      <template v-for="(el, ind) in state.counts" :key="ind">
        <div class="item dp-f ai-c" :class="'item' + ind">
          <img class="w-72 h-72 mr-16" :src="require('@/assets/img/account/item' + ind + '.png')">
          <div>
            <div class="mb-8">{{ el.value }}</div>
            <div class="fs-12 label">{{ el.label }}</div>
          </div>
        </div>
        <div class="line" v-if="ind != state.counts.length - 1"></div>
      </template>
    </div>
    <div class="w-500 bg-fff h-180 dp-f ai-c jc-sb pl-50 pr-4 cu-p" @click="Topup">
      <div>
        <div>套餐购买</div>
        <div class="fs-14 mt-8">点击此处进入 >></div>
      </div>
      <img src="@/assets/img/account/topup.png">
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, unref, computed,onMounted } from 'vue'
import {useRoute}from "vue-router"
import { router_push_name } from '@/utils/server/router.js'
import { httpToken } from "@/utils/request";
import { handleMessage } from "@/utils/server/confirm.js";
import { convert } from "@/utils/util"
import qs from "qs";
const countLoading = ref(false);//loading 显示
const Route=useRoute()
const state = reactive({
  counts: [
    { label: '剩余次数', value: 0 },
    { label: '总消费额(元)', value: 0 },
    { label: '已使用次数', value: 0 },
    // { label: '可开票金额', value: 100000 },
  ],
})
const userInfo = JSON.parse(window.sessionStorage.user).user_info


// 充值
const Topup = () => {
  // isAuthentication 企业实名认证 0是未认证 1是已认证
  if (userInfo.sysCompany.isauthentication == 0) {
    handleMessage('请先完成企业实名')
    return
  }
  router_push_name('account_topupsubject', undefined, undefined, true)
}
onMounted(()=>{
  if(Route.params.accountType==1){
    Topup()
  }
})
// 获取账户信息
const getAccount = () => {
  countLoading.value = true
  console.log("userinfo: ", userInfo)
  httpToken({
    method: "post",
    url: '/admin/account/getAccount',
  }).then(res => {
    state.counts[0].value = res.data.remainingAmount
    state.counts[1].value = convert(res.data.payAmount,false)
    state.counts[2].value = res.data.usedAmount
    countLoading.value = false;
  }).catch((err) => {
    countLoading.value = false;
  })
}
getAccount()
</script>
<style lang="scss" scoped >
.statisticsbox {
  font-size: 20px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 400;
  color: #F08722;

  .item {
    max-width: 28%;

    .label {
      font-family: PingFangSC-Regular, PingFang SC;
      color: #666666;
    }

    
  }
  .line {
      width: 1px;
      height: 88px;
      background: #E0E0E0;
    }
  .item1 {
    color: #F76B45;
  }

  .item2 {
    color: #4D88E7;
  }


}
</style>